<template>
    <DropdownScreenModal
        v-if="!isBotUserAgent"
        relation="header-location"
        :absolute="true"
        :prevent-scroll-fix="true"
        :prevent-closing-others="true"
        class-modifier="dropdown-screen--header-location"
        :use-custom-close-button="true"
        :use-slots-condition="true"
    >
        <p
            class="text-body font-medium text-center whitespace-pre-line break-words"
            :class="$bem({ e: 'text' })"
        >
            Вы находитесь в {{ locationSuggestion?.attributes.formattedTitle }}?
        </p>
        <div class="mt-6 -mx-2 flex items-center justify-center" :class="$bem({ e: 'buttons-group' })">
            <button
                data-relation="city-select"
                type="button"
                class="dropdown-entry button mx-2 !text-body-small !font-bold"
                @click="rejectSuggestion"
            >
                НЕТ
            </button>
            <button
                type="button"
                class="button button--black mx-2 !text-body-small !font-bold"
                :class="$bem({ e: 'button' })"
                @click="confirmSuggestion"
            >
                ДА
            </button>
        </div>
    </DropdownScreenModal>
</template>

<script lang="ts">
// #TODO: удалить только обновления vite и добавления exposeFilename
export default { name: 'TheHeaderIpLocate' }
</script>

<script lang="ts" setup>
import { onMounted, ref }              from "vue"
import DropdownScreenModal  from '~vue/dropdown-wrappers/DropdownScreenModal.vue'
import cartStore            from "~/store/cart"

import { JsonApiSingle }    from '~/interfaces/json-api'
import { Suggestion }       from "~/interfaces/cart/suggestion"

import { getCookie }        from "~/helpers/cookies"
import { fetchRequest }     from '~/helpers/fetch'
import { toggleElement }    from "~/client/vanilla-components/dropdown-screen"
import getDomainWithTLD     from '~/helpers/get-domain-with-tld'

const { cart } = cartStore.refs
const { getFormattedSuggestionData, parseSuggestionDataToDeliveryAddress } = cartStore.actions

let locationSuggestion  = ref(null as Suggestion | null)

const isCookieSet       = getCookie("is-city-selected") === "true"
const isBotUserAgent    = getCookie("isBotUserAgent") === "true"
const url               = '/module/cities/ajax?ajax=1&iplocate&suggestionType=city'

const processIpLocateResponse = (response: JsonApiSingle) => {
    if (response.data.id) {
        locationSuggestion.value = response.data as Suggestion
        toggleSelf()
    } else {
        setCookie()
    }
}

const rejectSuggestion = () => {
    window.dispatchEvent(new CustomEvent('lazy-mount:city-select'))
    window.dispatchEvent(new CustomEvent('dropdown:open', { detail: 'city-select' }))

    closeSelf()
}

const confirmSuggestion = () => {
    if (!locationSuggestion.value) return

    const formattedSuggestion = getFormattedSuggestionData(locationSuggestion.value)
    parseSuggestionDataToDeliveryAddress(formattedSuggestion)

    cart.value.selectedDeliveryTariff    = null
    cart.value.selectedZoneTariff        = null
    cart.value.selectedPickupPoint       = null
    cart.value.selectedPickupTariff      = null

    closeSelf()
}

const toggleSelf = () => {
    const dropdownHeaderLocationElement = document.querySelector<HTMLElement>('.dropdown-screen[data-relation="header-location"]')

    if (!dropdownHeaderLocationElement) return void 0

    toggleElement(dropdownHeaderLocationElement, true, true)
}

const setCookie = () => {
    document.cookie = `is-city-selected=true; domain=${getDomainWithTLD()}; path=/; max-age=2628000`
}

const redirectIfNeed = () => {
    if (locationSuggestion.value) {
        const redirectDomain = locationSuggestion.value.attributes.domain;

        if (redirectDomain && redirectDomain !== location.hostname) {
            const newURL = new URL(location.pathname, location.protocol + '//' + redirectDomain);

            location.href = newURL.toString()
        }
    }
}

const closeSelf = () => {
    toggleSelf()
    setCookie()
    redirectIfNeed()
}

onMounted(async () => {
    window.addEventListener('pagehide', () => {
        if (!isCookieSet) {
            confirmSuggestion()          
        }
    })
    
    try {
        if (isCookieSet) return

        const response = await fetchRequest(url, {
            method: 'GET',
        })

        if (!response) {
            throw new Error('Сервер не отвечает')
        } else {
            processIpLocateResponse(response)
        }
    } catch (error) {
        console.log(error) // Пока хз насчет того, что будет если ничего не пришло. Мб выбрасывать какое-то уведомление?
    }
})
</script>

