const setHeaderStyles = () => {
    const header = document.querySelector('.header-main') as HTMLElement
    let headerSearch = document.querySelector('.header-search') as HTMLElement

    const getOffset = () => {
        const bannerHeader = document.querySelector<HTMLElement>('#banners-header')
        const bannerMain = document.querySelector<HTMLElement>('.slider-banners--main, .catalog-menu-accordion')

        const bannerMainHeight = bannerMain ? bannerMain.getBoundingClientRect().height : 0
        const bannerHeaderHeight = bannerHeader ? bannerHeader.getBoundingClientRect().height : 0
        const headerHeight = header ? header.getBoundingClientRect().height : 0

        return bannerMainHeight + bannerHeaderHeight - headerHeight
    }

    let offset = getOffset()

    const setHeaderCssState = () => {
        const scrollPos = window.scrollY

        if (!headerSearch.parentElement) {
            //Костыль, т.к. сначала мы получаем поиск с ssr, потом ререндерим его как vue компонент
            headerSearch = document.querySelector('.header-search') as HTMLElement
        }

        if (scrollPos < 6) {
            header.classList.remove('header-main--blur')
            header.classList.add('header-main--transparent')
        } else if (scrollPos <= offset) {
            header.classList.add('header-main--blur', 'header-main--transparent')
            headerSearch.classList.remove('header-search--white')
        } else {
            header.classList.remove('header-main--blur', 'header-main--transparent')
            headerSearch.classList.add('header-search--white')
        }
    }

    setHeaderCssState()

    window.addEventListener('scroll', setHeaderCssState, { passive: true })
    window.addEventListener('resize', () => { offset = getOffset() })
}

const setHeaderDropdownEvents = () => {
    const headerMain = document.querySelector('.header-main') as HTMLElement
    let timerID: ReturnType<typeof setTimeout>

    window.addEventListener('dropdownOpen', (e) => {
        const dropdown = e.target as HTMLElement

        clearTimeout(timerID)

        const ignoreDropdowns = ['dropdown-screen--modal', 'dropdown-screen--filter']
        const isIgnoreDropdown = ignoreDropdowns.some((className) => dropdown.classList.contains(className))

        if (!isIgnoreDropdown) {
            headerMain.classList.add('header-main--with-dropdown')
        }
    })

    window.addEventListener('dropdownClose', () => {
        clearTimeout(timerID)

        timerID = setTimeout(() => headerMain.classList.remove('header-main--with-dropdown'), 525)
    })
}

document.addEventListener('DOMContentLoaded', setHeaderDropdownEvents, {once: true})

if (window.location.pathname === '/') {
    document.addEventListener('DOMContentLoaded', setHeaderStyles, {once: true})
    document.addEventListener('header-search-mounted', setHeaderStyles, {once: true})
    window.addEventListener('load', setHeaderStyles, {once: true})
}
