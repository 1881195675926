import 'requestidlecallback-polyfill'
import './header/header'
import './header/header-about'
import './header/header-banner'
import './css-vars'
import './dropdown-screen'
import './tags-all'
import './tabs-card-block'
import './ajax-product-loader'
import './layout-side-buttons'
import './wishlist'
import './comparison'
import './ajax-product-loader-index'
import './third-party-scripts'
import './ajax-static-request-modals'
import './tabs'
import './breadcrumbs'
import './save-scroll-position'
import './tooltip-button'
import './marquiz'
import './product-card'
import './category-card'

// import './happy-new-year'
// import './animated-logo'
import './safari-blur-on-scroll'
import '../vanilla-components/section/carousel-videos'
import asyncInitQueueHandle from '~/helpers/async-init-queue-handle'
import './detect'

import {useOnIntersection} from "~/helpers/on-intersection"

const asyncInitQueue = [
    {
        containerSelector: '.dropdown-screen[data-relation="yt-dropdown-video"]',
        loadEventType: 'DOMContentLoaded',
        importFn: () => import('./dropdown-videos')
    },
    {
        containerSelector: '.faq',
        loadEventType: 'load',
        importFn: () => import('./faq')

    },
    {
        containerSelector: '.input-count',
        loadEventType: 'DOMContentLoaded',
        importFn: () => import('./input-count')

    },
    {
        containerSelector: '.swipe-block',
        loadEventType: 'DOMContentLoaded',
        importFn: () => import('./swipe-block')

    },
    {
        containerSelector: '.dropdown-screen--cookie-policy',
        loadEventType: 'DOMContentLoaded',
        importFn: () => import('./cookie-policy-modal')

    },
    {
        containerSelector: '.tags',
        loadEventType: 'DOMContentLoaded',
        importFn: () => import('./tags')

    },
    {
        containerSelector: '#actions-list',
        loadEventType: 'DOMContentLoaded',
        importFn: () => import('./actions-list')
    },
    {
        containerSelector: '.action-teaser',
        loadEventType: 'DOMContentLoaded',
        importFn: () => import('./action-teaser')
    }
] as AsyncInitQueueItem[]

asyncInitQueueHandle(asyncInitQueue)

const {initObserver} = useOnIntersection({
    'footer-root': () => import('./footer-menu')
})

window.addEventListener('load', initObserver, {once: true})
