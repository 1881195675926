const root = document.documentElement
const rootStyle = root.style
const mainBlock = document.querySelector('main') as HTMLElement
const headerBlock = document.querySelector('header#header') as HTMLElement
const headerTopBlock = document.querySelector('.header-top') as HTMLElement

const setCssScrollWidthVar = () => {
    requestAnimationFrame(() => {
        rootStyle.setProperty('--scroll-w', window.innerWidth - root.clientWidth + 'px')
    })
}

const setCssOffsetVars = () => {
    requestAnimationFrame(() => {
        const mainOffset = mainBlock.getBoundingClientRect().y
        const headerHeight = headerBlock.getBoundingClientRect().height
        const headerTopRects = headerTopBlock.getBoundingClientRect()
        const mainTopOffset = mainOffset > headerHeight ? mainOffset : headerHeight
        const headerTopOffset = headerTopRects.height + headerTopRects.y

        rootStyle.setProperty('--main-top-offset', `${mainTopOffset}px`)
        rootStyle.setProperty('--header-top-offset', `${headerTopOffset}px`)
    })
}

document.addEventListener('DOMContentLoaded', () => {
    setCssScrollWidthVar()
    setCssOffsetVars()
}, {once: true})

window.addEventListener('load', () => {
    setCssScrollWidthVar()
    setCssOffsetVars()
}, {once: true})

window.addEventListener('resize', () => {
    setCssScrollWidthVar()
    setCssOffsetVars()
})

window.addEventListener('scroll', () => {
    setCssOffsetVars()
}, { passive: true })
