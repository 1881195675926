import { fetchRequest } from "~/helpers/fetch"
import { WindowCustom } from "~/interfaces/window"
import { yandexDatalayerForm }     from '~/helpers/yandex-datalayer'
import { mindboxPushWishlist }              from '~/helpers/mindbox-push'
import getDomainWithTLD                     from '~/helpers/get-domain-with-tld'

let wishlistProductsIds: number[] = []

window.addEventListener('DOMContentLoaded', () => {
    setIdsFromWindow()
    wishlistButtonsStatusRefresh()
    delegateClickListeners()
})

const setIdsFromWindow = () => {
    // Модуль с бэка пропихивает значения в window, а значит пока не переписали и бэк, оставляю так

    const windowCustom: WindowCustom = window

    if (windowCustom.wishlistProductsIds) wishlistProductsIds = windowCustom.wishlistProductsIds
}

const wishlistButtonsStatusRefresh = () => {
    const wishlistButtons = document.querySelectorAll<HTMLElement>('.button-wishlist-add')

    if (!wishlistButtons.length) return void 0

    wishlistButtons.forEach((el: HTMLElement) => {
        const currentId = el.dataset.idProduct
        const serviceButtonElement = el.querySelector<HTMLElement>('.service-button')

        if (!currentId) return void 0

        if (wishlistProductsIds.includes(Number(currentId))) {
            el.classList.add('favorite--active')
            serviceButtonElement?.classList.add('service-button--active')
        } else {
            el.classList.remove('favorite--active')
            serviceButtonElement?.classList.remove('service-button--active')
        }
    })
}

const wishlistPageCounterRefresh = () => {
    const pageTitleElement = document.getElementById('wishlist')
    const pageProductsCount = pageTitleElement?.querySelector<HTMLElement>('.wishlist-count')

    if (!pageTitleElement || !pageProductsCount) return void 0

    pageProductsCount.innerText = String(wishlistProductsIds.length)
}

const updateMobilePanel = () => {
    const mobilePanelWishlistBadgeElement = document.querySelector<HTMLElement>('.mobile-navigation__item--favorite .service-button__badge')

    if (!mobilePanelWishlistBadgeElement) return void 0

    mobilePanelWishlistBadgeElement.innerText = String(wishlistProductsIds.length)

    wishlistProductsIds.length
        ? mobilePanelWishlistBadgeElement.classList.remove('service-button__badge--hidden')
        : mobilePanelWishlistBadgeElement.classList.add('service-button__badge--hidden')
}

const updateHeaderPanel = () => {
    const headerPanelWishlistBadgeElement = document.querySelector<HTMLElement>('.header-wishlist .badge')

    if (!headerPanelWishlistBadgeElement) return void 0

    headerPanelWishlistBadgeElement.innerText = String(wishlistProductsIds.length)

    wishlistProductsIds.length
        ? headerPanelWishlistBadgeElement.classList.remove('badge--hidden')
        : headerPanelWishlistBadgeElement.classList.add('badge--hidden')
}

const delegateClickListeners = () => {
    window.addEventListener('click', (e: Event) => {
        let target = <HTMLElement>e.target

        if (!target.classList.contains('button-wishlist-add')) {
            const buttonCompareAddContainer = target.closest<HTMLElement>('.button-wishlist-add')

            if (!buttonCompareAddContainer) return void 0

            target = buttonCompareAddContainer
        }

        const productId = target.dataset.idProduct
        const priceItem = Number(target.dataset.price)

        if (!target.classList.contains('button-wishlist-add') || !productId || !priceItem) return void 0

        wishListAdd(productId, priceItem)
    })
}

const removeProductOnPage = (productId: string) => {
    const pageTitleElement = document.getElementById('wishlist')

    if (!pageTitleElement) return void 0

    const productCards = document.querySelectorAll<HTMLElement>('.card')

    if (!productCards.length) return void 0

    if (productCards.length === 1)  {
        changeWishlistCategory()
        return void 0
    }

    const productCard = Array.from(productCards).find(element => element.dataset.idProduct == productId)

    if (!productCard) return void 0

    productCard.parentElement?.remove()
}

const changeWishlistCategory = () => {
    const tags = document.querySelectorAll<HTMLElement>('a.tags__item')
    const categoryId = (new URLSearchParams(window.location.search)).get('category')

    if (!tags.length) return void 0

    const newTag = Array.from(tags).find(element => element.hasAttribute('href') && element.dataset.id != categoryId)

    newTag ? newTag.click() : window.location.reload()
}

const wishListAdd = async (productId: string, price: number) => {
    const action = wishlistProductsIds.includes(Number(productId)) ? 'remove' : 'add'
    const url = `/module/eowishlist/mywishlist?ajax=1&action=${action}&id_product=${productId}`

    try {
        const response = await fetchRequest(url, {
            method: 'GET',
        })

        if (action === 'add' && response !== 0) {
            wishlistProductsIds.push(Number(productId))
            updateMobilePanel()
            updateHeaderPanel()
            wishlistButtonsStatusRefresh()

            yandexDatalayerForm('favorite')
            let metodMindbox = getDomainWithTLD() == 'express-office.ru' ? 'Website.AddToWishList1' : 'AddProductToIzbrannoeH24ItemList';
            mindboxPushWishlist(Number(productId), price, metodMindbox)
        } else if (action === 'remove' && response !== 0) {
            wishlistProductsIds.splice(
                wishlistProductsIds.indexOf(Number(productId)),
                1
            )
            updateMobilePanel()
            updateHeaderPanel()
            wishlistButtonsStatusRefresh()
            wishlistPageCounterRefresh()
            removeProductOnPage(productId)
            let metodMindbox = getDomainWithTLD() == 'express-office.ru' ? 'RemoveProductFromIzbrannoeItemList' : 'RemoveProductFromIzbrannoeH24ItemList';
            mindboxPushWishlist(Number(productId), price, metodMindbox)
        }
    } catch (error) {
        console.error(error)
    }
}


