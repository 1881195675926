<script setup lang="ts">
import {computed, ref, watch} from 'vue'
import {CatalogMenuCategory, CatalogMenuLink, CatalogMenuTab} from "~vue/catalog-menu/types"
import {saveSelectedMenuCategory} from "~vue/catalog-menu/lib"

const props = defineProps<{
    tabs: CatalogMenuTab[]
    standaloneLinks?: CatalogMenuLink[]
    modelValue: CatalogMenuTab,
    category: CatalogMenuCategory
}>()

const emit = defineEmits(['update:modelValue', 'update:category'])

const activeTab = ref(props.modelValue)
const activeCategory = ref(props.category)

const stationeryTabId = '5478363'

// скрываем канцтовары
const _tabs = computed(() => props.tabs.filter((tab) => tab.id != stationeryTabId))

watch(() => props.modelValue, (newValue) => {
    activeTab.value = newValue
})

watch(() => props.category, (newCategory) => {
    activeCategory.value = newCategory
})

const categoryClick = (e: Event, item: CatalogMenuCategory) => {
    if (item.groups?.length) {
        e.preventDefault()
        saveSelectedMenuCategory(item.id)

        if (window.innerWidth < 640 && activeCategory.value.id === item.id) {
            document.dispatchEvent(new CustomEvent('showMenuGroups'))
        }

        activeCategory.value = item
        emit('update:category', item)
    }
}
</script>

<template>
    <div class="menu-links">
        <div
            v-for="tab in _tabs"
            :key="tab.id"
            class="menu-links__item"
        >
            <ul
                v-if="tab.id === activeTab.id"
                :id="`menu-links-content-${tab.id}`"
                :key="tab.id"
                :aria-hidden="tab.id !== activeTab.id"
                class="menu-links__list"
                :aria-label="`Подразделы раздела каталога ${tab.title}`"
            >
                <li
                    v-for="category in tab.categories"
                    :key="category.id"
                    class="menu-links__list-item"
                >
                    <img
                        v-if="category.icon"
                        loading="lazy"
                        class="menu-links__img"
                        :src="`/upload/menu-icons/${category.icon}.svg`"
                        :alt="`Иконка ${category.title}`"
                    >
                    <a
                        :href="category.url"
                        :class="{
                            'menu-links__link': true,
                            'menu-links__link--highlight': category.id === activeCategory.id,
                        }"
                        @click="(e) => categoryClick(e, category)"
                    >
                        {{ category.title }}
                    </a>
                </li>
            </ul>
        </div>

        <ul
            v-if="standaloneLinks?.length"
            class="menu-links__list menu-links__list--standalone"
            aria-label="Основные разделы каталога"
        >
            <li
                v-for="link in standaloneLinks"
                :key="link.url"
                class="menu-links__list-item"
            >
                <a
                    :href="link.url"
                    :class="{
                        'menu-links__link': true,
                        'menu-links__link--standalone': true,
                        'menu-links__link--highlight': link.highlight,
                    }"
                >
                    {{ link.title }}
                </a>
            </li>
        </ul>
    </div>
</template>

<style scoped lang="scss">
$className: "menu-links";

.#{$className} {
    @apply
        flex
        flex-col
        gap-4
    ;

    &__buttons {
        @apply
            flex
            mb-6
            z-[4]
            gap-6
        ;
    }

    &__button {
        transition-property: color, font-size;

        @apply
            uppercase
            text-pale-sky-70
            border-none
            duration-300
            ease-ease
        ;

        &--selected {
            @apply
                text-black
                text-menu-item
            ;
        }
    }

    &__contents {
        @apply z-[4];

        &:not(&:last-child) {
            @apply mb-4;
        }
    }

    &__item {
        @apply z-[4];
    }

    &__list {
        @apply
            flex
            flex-col
            gap-2
            mt-2
        ;

        &--standalone {
            @apply
                z-[4]
                gap-4
                mt-0
            ;
        }
    }

    &__list-item {
        @apply
            flex
            gap-2
            text-pale-sky-70
            items-center
            relative
        ;
    }

    &__link {
        @apply text-pale-sky-70;

        &::after {
            content: '';

            @apply
                absolute
                inset-0
            ;
        }

        &:not(&--standalone) {
            &:hover {
                @apply text-black;
            }
        }

        &--standalone {
            @apply uppercase;
        }

        &--highlight {
            @apply text-orange;
        }
    }

    &__img {
        background: url(/assets/images/dummy-white-box.svg) center no-repeat;

        @apply
            text-[0]
            w-10
            h-10
            flex-shrink-0
        ;
    }
}
</style>
