window.addEventListener('load', () => {
    const dropdownButton = document.querySelector<HTMLElement>('.header-customers__button')

    const isAboutDropdown = (el: HTMLElement) => {
        return Boolean(
            el.classList.contains('dropdown-screen')
            && el.getAttribute('data-relation') === 'header-about-toggle'
        )
    }

    const addButtonClass = (e: Event) => {
        if (isAboutDropdown(e.target as HTMLElement)) {
            dropdownButton?.classList.add('header-customers__button--open')
        }
    }

    const removeButtonClass = (e: Event) => {
        if (isAboutDropdown(e.target as HTMLElement)) {
            dropdownButton?.classList.remove('header-customers__button--open')
        }
    }

    if (dropdownButton) {
        window.addEventListener('dropdownOpen', addButtonClass)
        window.addEventListener('dropdownClose', removeButtonClass)
    }
}, {once: true})

