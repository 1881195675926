import 'keen-slider/keen-slider.min.css'

import bem from "bem-ts"
import KeenSliderHelper from '~/classes/keen-slider-helper'
import { googleDataMarketingBanner, googleDataMarketingBannerClick  } from '~/helpers/google-datalayer'

const _bemBlock = bem("header-banner", { strict: false })

const headerPromoMessage: HTMLElement               = document.querySelector(`.${_bemBlock()}__copied-info`) as HTMLElement
const headerPromoBanner: NodeListOf<HTMLElement>    = document.querySelectorAll('[data-promo]') as NodeListOf<HTMLElement>

window.addEventListener('DOMContentLoaded', () => {
    setTimeout(() => {
        const container         = document.getElementById('banners-header') as HTMLElement
        const slides            = container?.querySelectorAll('.keen-slider__slide') as NodeListOf<HTMLElement>
        const initialSlide      = Math.floor(Math.random() * slides?.length)

        if (!container) return void 0

        container.addEventListener('click', (e: Event) => {
            const eventTarget = e.target as HTMLElement
            const currentSlide = eventTarget.closest<HTMLElement>('.keen-slider__slide--current')

            if (currentSlide) {
                googleDataMarketingBannerClick(currentSlide)
            }
        })

        const slider = new KeenSliderHelper(
            container,
            {
                initial: initialSlide,
                loop: false,
                drag: false,
                slides: {
                    perView: 1,
                },
            },
        )

        slider.createAdditionalMarkup({
            popup: {
                enable: true,
                selector: '.slider-banners__adv-button'
            }
        })

        slider.instance.slides.forEach(element => {
            if(element.classList.contains(`keen-slider__slide--current`)) googleDataMarketingBanner(element)
        })
    })

    // копирование промокода с баннера
    headerPromoBanner.forEach((banner: HTMLElement) => {
        banner?.addEventListener('click', () => {
            const promocode = banner.dataset.promo as string

            navigator.clipboard.writeText(promocode)
            if (headerPromoMessage?.classList?.contains(`${_bemBlock()}__copied-info--opened`) === false) {
                headerPromoMessage?.classList?.add(`${_bemBlock()}__copied-info--opened`)

                setTimeout(()=> headerPromoMessage?.classList?.remove(`${_bemBlock()}__copied-info--opened`), 3000)
            }
        })
    })
})

window.addEventListener('scroll', () => {
    headerPromoMessage?.classList?.remove(`${_bemBlock()}__copied-info--opened`)
})
